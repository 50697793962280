import axios from "axios";
import { headers } from "../../utils";
import _ from "lodash";

const state = {
    allMessages: [],
    message: {},
}

const getters = {
    getAllMessages(state){
        return state.allMessages
    },
    getUserMessage(state){
        return state.message
    }
}

const mutations = {
    setAllMessages(state, payload){
        state.allMessages = _.orderBy(payload, ["id"], ["desc"]);
    },
    setUserMessage(state, payload){
        state.messages = _.orderBy(payload, ["id"], ["desc"]);
    },
    setDefaultsMessage(state){
        state.allMessages = []
        state.messages = []
    }
}

const actions = {
    getAllMessages({commit}){
        axios.get(process.env.VUE_APP_BASE_URL + '/api/messages', headers())
        .then(res => {
            console.log({res})
            commit('setAllMessages',res.data.data)
            return true
        })
        .catch(err => {
            console.log(err)
            return err.response
        })
    },
    getPropertyMessages({commit}, payload){
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/property-messages/${payload}`, headers())
        .then(res => {
            console.log("in property messages", res)
            return res
        })
        .catch(err => {
            console.log(err)
            return err.response
        })
    },
    getUserMessage({commit}, payload){
        axios.get(process.env.VUE_APP_BASE_URL + `/api/messages/${payload}`, headers())
        .then(res => {
            console.log({res})
            commit('setUserMessage',res)
            return true
        })
        .catch(err => {
            console.log(err)
            return err.response
        })
    },
    createMessage({commit}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/messages', payload, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    updateMessage({commit}, payload){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/messages/${payload.id}`, payload.data, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    deleteMessage({commit}, payload){
        return axios.delete(process.env.VUE_APP_BASE_URL + `/api/messages/${payload.id}`, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    updateMessageStatus({commit}, payload){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/messages/update-status/${payload}`,{}, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}