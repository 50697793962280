import { store } from "../store/index"
import _ from "lodash";

export default async (to, from, next) => {
    let draft = store.getters['getCurrentDraft'] ? JSON.parse(store.getters['getCurrentDraft']?.property) : null
    if(draft){
    if(draft.epcCertificate){
        if(Object.keys(draft.epcCertificate).length)
            store.commit('setIndex', 6)
    }
    else if(draft.floorPlan.length > 0)
        store.commit('setIndex', 5)
    else if(draft.images.length)
        store.commit('setIndex', 4)
    else if(_.get(draft, 'description[0].value.mainDescription', null)){
        store.commit('setIndex', 3)
    }
    else if(draft.price && draft.noOfBedrooms && draft.noOfBathrooms && draft.propertyType && draft.tenure)
        store.commit('setIndex', 2)
    else if(draft.houseNo.length)
        store.commit('setIndex', 1)
}
    next()
}
