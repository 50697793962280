import axios from "axios";
import { headers } from "../../utils";

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
    createPayment({commit}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/payment/stripe/pay', payload, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    createStripePayment({commit}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/payment/paypal/pay', payload, headers())
        .then(res => {
            console.log({res})
            return res
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}