import axios from "axios";
import { headers } from "../../utils";

const state = {
    referralCount: 0,
    referralPoints: 0,
    redeem: 0,
}

const getters = {
    getReferralCount(state){
        return state.referralCount
    },
    getReferralPoints(state){
        return state.referralPoints
    },
    getRedeem(state){
        return state.redeem
    }
}

const mutations = {
    setReferralCount(state, payload){
        state.referralCount = payload
    },
    setReferralPoints(state, payload){
        state.referralPoints = payload
    },
    setDefaultsReferral(state){
        state.referralCount = 0
        state.referralPoints = 0
        state.redeem = 0
    },
    setRedeem(state, payload){
        state.redeem = payload ? 1 : 0
    }
}

const actions = {
    referralCount({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/referral/user-referral/count-by-referrer', headers())
        .then(res => {
            commit('setReferralCount', res.data.data)
            return true
        })
        .catch(err => {
            console.log(err)
            return err.response
        })
    },
    sendRequestIfCookie({commit}, payload){
		const data = {
			referralCode : payload
		}
		return axios.post(process.env.VUE_APP_BASE_URL + "/api/referral/user-referral/create", data , headers())
		.then(() => {
            return true
		})
		.catch(err => {
			console.log(err)
            return err.response
		})
	},
    redeemPoints({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/referral/user-referral/redeem-points', headers())
        .then(res => {
            return true
        })
        .catch(err => {
            console.log(err)
            return err.response
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}