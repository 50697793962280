import { store } from "../store/index"

export default async (to, from, next) => {
    let isLoggedIn = store.getters['check']
    if(!isLoggedIn)
        await store.dispatch('getUser')
    if(!store.getters['check']){
        next('/login')
    }
    else{
        if(store.getters['auth'].is_active == 1){
            next('/')
        }
        else if(store.getters['auth'].is_active == 0){
            next('/email-verification')
        }
    }
}