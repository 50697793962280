import axios from "axios";
import { headers } from "../../utils";
import _ from "lodash";

const state = {
    loginData: {},
    registerData: {},
    auth: {},
    check: false,
    userById: {},
    buyerSeller: {},
}

const getters = {
    getRegisterData(state){
        return state.registerData
    },
    getLoginData(state){
        return state.loginData
    },
    auth (state) {
		return state.auth
	},
	check (state) {
		return state.check
	},
    getUserById(state){
        return state.userById
    },
    getBuyerSeller(state){
		return state.buyerSeller
	}
}

const mutations = {
    setRegisterData(state, payload){
        state.registerData = payload
    },
    setLoginData(state, payload){
        state.loginData = payload
    },
    set(state, auth) {
		if(!(_.isEmpty(auth))){
			auth.userName = _.startCase(_.lowerCase(auth.userName))
		}
		state.auth = auth
	},
	setCheck(state, check) {
		state.check = check
	},
	accessToken(state, params) {
		localStorage.setItem('shopper_access_token' , params.accessToken)
		axios.setToken(params.accessToken, 'Bearer')
		state.check = true
	},
	setUserById(state, payload){
		state.userById = payload
	},
	setDefaultsAuth(state){
		state.registerData = {}
		state.loginData = {}
		state.auth = {}
		state.check = false
	},
	setBuyerSeller(state, payload){
		state.buyerSeller = payload
	},
}

const actions = {
    register({commit}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/register', payload)
        .then(res => {
            commit('setRegisterData', res.data)
            localStorage.setItem('buyer_access_token', res.data.accessToken)
            localStorage.setItem('verification_code_time', res.data.sendTime)
            localStorage.setItem('verification_code_limit', res.data.timeLimit)
            return true
        })
        .catch(err => {
            console.log(err)
            return err.response
        })
    },
    login({commit}, payload){
      console.log({payload})
        return axios.post(process.env.VUE_APP_BASE_URL + '/oauth/token', payload)
        .then(res => {
            commit('setLoginData', res.data)
            localStorage.setItem('buyer_access_token', res.data.access_token)
            return true
        })
        .catch(err => {
            console.log(err)
            return err.response
        })
    },
    getUser({ commit }) {
        return axios
          .get(process.env.VUE_APP_BASE_URL + '/api/user', headers())
          .then(res => {
            commit("set", res.data)
            commit('setCheck', true)
            return true
          })
          .catch(err => {
            return err.response
          });
      },
    logout({ commit }) {
        return axios
          .get(process.env.VUE_APP_BASE_URL + '/api/logout', headers())
          .then(() => {
            commit("set", {});
            commit("setCheck", false);
            localStorage.removeItem("buyer_access_token");
            return true
          })
          .catch(err => {
            return err.response;
          });
      },
    verifyCode({ commit }, payload){
      return axios.post(process.env.VUE_APP_BASE_URL + "/api/verify-code", payload, headers())
      .then(() => {
        return true
      })
      .catch(err => {
        return err.response
      })
    },
    resendVerifyCode({ commit }){
      return axios.get(process.env.VUE_APP_BASE_URL + '/api/resend-verify-code', headers())
      .then(res => {
        return res
      })
      .catch(err => {
        return err.response
      })
    },
    getUserById({commit}, payload){
      return axios.get(process.env.VUE_APP_BASE_URL + `/api/user/${payload}`, headers())
      .then(res => {
        console.log({res})
        return res.data
      })
      .catch(err => {
        console.log({err})
        return err.response
      })
    },
    getBuyerSeller({commit}, payload){
      return axios.get(process.env.VUE_APP_BASE_URL + `/api/buyer-seller/${payload}`, headers())
      .then(res => {
        console.log({res})
        commit('setBuyerSeller', res.data.data)
        return res.data
      })
      .catch(err => {
        console.log({err})
        return err.response
      })
    },
    getBuyerSellerForBlog({commit}, payload){
      return axios.get(process.env.VUE_APP_BASE_URL + `/api/buyer-seller/${payload}`, headers())
      .then(res => {
        console.log({res})
        return res
      })
      .catch(err => {
        console.log({err})
        return err.response
      })
    },
    updateProfile({commit}, payload){
      return axios.put(process.env.VUE_APP_BASE_URL + `/api/buyer-seller/update-profile`, payload, headers())
      .then(res => {
        console.log({res})
        return true
      })
      .catch(err => {
        console.log({err})
        return err.response
      })
    },
    forgotPassword({commit}, payload){
      return axios.post(process.env.VUE_APP_BASE_URL + '/api/forgot-password', payload, headers())
      .then(res => {
        console.log(res)
        return true
      })
      .catch(err =>{
        console.log(err)
        return err.sresponse
      })
    },
    resetPassword({commit}, payload){
      return axios.post(process.env.VUE_APP_BASE_URL + '/api/reset-password', payload, headers())
      .then(res => {
        console.log(res)
        return true
      })
      .catch(err =>{
        console.log(err)
        return err.response
      })
    },
    addUserName({commit}, payload){
      return axios.put(process.env.VUE_APP_BASE_URL + '/api/auth-user/update', payload, headers())
      .then(res => {
        console.log(res)
        return true
      })
      .catch(err =>{
        console.log(err)
        return err.response
      })
    },
    updateUserName({commit}, payload){
      return axios.put(process.env.VUE_APP_BASE_URL + `/api/auth-user/update`, payload, headers())
      .then(res => {
        console.log({res})
        return true
      })
      .catch(err => {
        console.log({err})
        return err.response
      })
    },
    deleteAccount({}){
      return axios.delete(process.env.VUE_APP_BASE_URL + `/api/delete-account`, headers())
      .then(res => {
        console.log({res})
        return true
      })
      .catch(err => {
        console.log({err})
        return err.response
      })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}