import axios from "axios";
import { headers } from "../../utils";

const state = {
    blockedUsers: [],
    blockedByUsers: [],
}

const getters = {
    getBlockedUsers(state){
        return state.blockedUsers
    },
    getBlockedByUsers(state){
        return state.blockedByUsers
    }
}

const mutations = {
    setBlockedUsers(state, payload){
        state.blockedUsers = payload
    },
    setBlockedByUsers(state, payload){
        state.blockedByUsers = payload
    },
    setDefaultsReport(state){
        state.blockedUsers = []
        state.blockedByUsers = []
    }
}

const actions = {
    blockedUsers({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/blocked-users', headers())
        .then(res => {
            commit('setBlockedUsers', res.data.data)
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    blockedBy({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/blocked-by', headers())
        .then(res => {
            commit('setBlockedByUsers', res.data.data)
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    reportUser({}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/report',payload, headers())
        .then(() => {
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    blockUser({}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/report',payload, headers())
        .then(() => {
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    unBlockUser({}, payload){
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/un-block-user/${payload}`, headers())
        .then(() => {
            return true
        })
        .catch(err => {
            return err.response
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}