export const capitalize = (str) => {
  if (str) {
    let tokens = str.split(' ')
    let converted = ""
    for (let i = 0; i < tokens.length; i++) {
      converted = converted + `${tokens[i].charAt(0).toUpperCase()}${tokens[i].substr(1, tokens[i].length)}`.trim() + `${tokens[i + 1] ? ' ' : ''}`
    }
    return converted
  }
  return str
}

export const onlyNumberInput = (event) => {
  if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) && event.keyCode !== 8 && event.keyCode !== 9) {
    event.preventDefault()
  }
}

export const formatter = (amount) => {
  var formatter = new Intl.NumberFormat(undefined, {});
  return formatter.format(amount);
}

export const headers = () => {
  return {
    headers:{
      'Authorization': 'Bearer ' + localStorage.getItem('buyer_access_token')
    },
  }
}