import { store } from "../store/index";

export default async (to, from, next) => {
  if (to.meta.loginRequired) {
    let isLoggedIn = store.getters["check"];
    if (!isLoggedIn) await store.dispatch("getUser");
    if (!store.getters["check"]) {
      next("/login");
    } else {
      if(typeof store.getters["auth"].user_name !== 'string'){
        next("/username-unset")
      }
      else if (store.getters["auth"].is_active == 0) {
        next("/email-verification");
      } else if (store.getters["auth"].is_active == 2) {
        next("/blocked");
      }
      else{
        next()
      }
    }
  } else {
    next();
  }
};
