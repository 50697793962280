import axios from "axios";
import { headers } from "../../utils";

const state = {
    shouldShow: false,
    message: "",
    showLoader: false,
    loginRequired: false,
}

const getters = {
    getShouldShow(state){
        return state.shouldShow
    },
    getMessage(state){
        return state.message
    },
    getShowLoader(state){
        return state.showLoader
    },
    getLoginRequired(state){
        return state.loginRequired
    }
}

const mutations = {
    setMessage(state, payload){
        state.message = payload
        state.shouldShow = true
    },
    setShouldShow(state, payload){
        state.shouldShow = payload
    },
    setShowLoader(state, payload){
        state.showLoader = payload
    },
    setLoginRequired(state, payload){
        state.loginRequired = payload
    },
    setDefaultsMiscellaneous(state){
        state.shouldShow = false
        state.message = ""
        state.showLoader = false
        state.loginRequired = false
    }
}

const actions = {
    traceIp(){
        return axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(data => {
            data = data.trim().split('\n').reduce(function(obj, pair) {
                pair = pair.split('=');
                return obj[pair[0]] = pair[1], obj;
              }, {});
              return data
        })
        .catch(err => {
            return err.response
        })
    },
    plantTree({}, payload){
        return axios.post('https://public.ecologi.com/impact/carbon', payload, {
            headers:{
				'Authorization': `Bearer ${process.env.VUE_APP_ECOLOGI_KEY}`,
                'Content-Type': 'application/json'
			},
        })
        .then(plantTree => {
            console.log(plantTree)
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    ecologi({}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/ecologi', payload, headers())
        .then(ecologi => {
            console.log(ecologi)
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    contactUs({}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/contact-us', payload)
        .then(contactUs => {
            console.log(contactUs)
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    brandAmbassador({}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/grand-ambassador', payload)
        .then(brandAmbassador => {
            console.log(brandAmbassador)
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    encrypt({}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/encrypt', payload, headers())
        .then(encrypt => {
            console.log(encrypt)
            return encrypt
        })
        .catch(err => {
            return err.response
        })
    },
    decrypt({}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/decrypt', payload, headers())
        .then(decrypt => {
            console.log(decrypt)
            return decrypt
        })
        .catch(err => {
            return err.response
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}