import { required, minLength, email } from "vuelidate/lib/validators";
export default {
    data(){
        return{
            email: "",
            password: "",
            invalidLoginDetails: false,
            hasClicked: false,
        }
    },
    watch:{
        email(){
            if(this.hasClicked)
                this.invalidLoginDetails = false
        },
        password(){
            if(this.hasClicked)
                this.invalidLoginDetails = false
        }
    },
    validations:{
        email:{
            required,
            email
        },
        password:{
            required,
            minLength: minLength(8)
        }
    },
    methods:{
        googleLogin() {
			window.location = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_SOCIAL_GOOGLE_REDIRECT_URL
	 	},
    	facebookLogin() {
			window.location = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_SOCIAL_FACEBOOK_REDIRECT_URL
	 	},
        async login(){
            if(this.$v.$invalid){
                if(this.$v.email.$invalid)
                    this.$v.email.$touch()
                if(this.$v.password.$invalid)
                    this.$v.password.$touch()
            }
            else{
                this.hasClicked = true
                this.$store.commit('setShowLoader', true)
            const data = {
                grant_type: 'password',
                client_id: process.env.VUE_APP_client_id,
                username: this.email,
                password: this.password,
                client_secret: process.env.VUE_APP_client_secret
            }
            await this.$store.dispatch('login', data).then(res => {
                this.$store.commit('setShowLoader', false)
                if(res == true){
                    if(Object.keys(this.$route.query).includes('sell'))
                        this.$router.push('/add-property')
                    else if(Object.keys(this.$route.query).includes('get-started'))
                        this.$router.push('/welcome')
                    else 
                        this.$router.push('/')
                }
                else if(res.status == 400 && res.data.error === 'invalid_grant'){
                    this.invalidLoginDetails = true
                }
            })
            }
        }
    },
}