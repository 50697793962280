import axios from "axios";
import { headers } from "../../utils";
import _ from "lodash";

const state = {
    total: 0,
    userProperties: [],
    properties: [],
    property: {},
    myBuying: [],
    myOffers: [],
    myViewing: [],
    propertyMessages: [],
    favourites: [],
    packages: [],
    propertyDraft: null,
    index: 0,
    suggestions: [],
    selectedDraft: null,
}

const getters = {
    getUserProperties(state){
        return state.userProperties
    },
    getProperties(state){
        return state.properties
    },
    getProperty(state){
        return state.property
    },
    getMyBuying(state){
        return state.myBuying
    },
    getMyOffers(state){
        return state.myOffers
    },
    getMyViewing(state){
        return state.myViewing
    },
    getPropertyMessages(state){
        return state.propertyMessages
    },
    getFavourites(state){
        return state.favourites
    },
    getPackages(state){
        return state.packages
    },
    getPropertyDraft(state){
        return state.propertyDraft
    },
    getIndex(state){
        return state.index
    },
    getSuggestions(state){
        return state.suggestions
    },
    getCurrentDraft(state){
		return state.selectedDraft
	},
    getTotal(state){
        return state.total
    }
}

const mutations = {
    setUserProperties(state, payload){
        state.userProperties = _.orderBy(payload, ["id"], ["desc"]);
    },
    setProperties(state, payload){
        state.properties = _.orderBy(payload, ["id"], ["desc"]);
    },
    setProperty(state, payload){
        state.property = payload
    },
    setAllProperties(state, payload){
        state.properties = payload
    },
    setDefaultsProperty(state){
        state.total = 0
        state.userProperties = []
        state.properties = []
        state.property = {}
        state.myBuying = []
        state.myOffers = []
        state.myViewing = []
        state.propertyMessages = []
        state.favourites = []
        state.packages = []
        state.propertyDraft = null
        state.index = 0
        state.suggestions = []
        state.selectedDraft = null
    },
    setMyBuying(state, payload){
        state.myBuying = _.orderBy(payload, ["id"], ["desc"]);
    },
    setMyOffers(state, payload){
        state.myOffers = _.orderBy(payload, ["id"], ["desc"]);
    },
    setMyViewing(state, payload){
        state.myViewing = _.orderBy(payload, ["id"], ["desc"]);
    },
    setPropertyMessages(state, payload){
        state.propertyMessages = _.orderBy(payload, ["id"], ["desc"]);
    },
    setFavourites(state, payload){
        state.favourites = _.orderBy(payload, ["id"], ["desc"]);
    },
    setPackages(state, payload){
        state.packages = payload
    },
    setPropertyDraft(state, payload){
        state.propertyDraft = payload
    },
    setIndex(state, payload){
        state.index = payload
    },
    setSuggestions(state, payload){
        state.suggestions = payload
    },
    setCurrentDraft(state, payload){
		state.selectedDraft = payload
	},
    setTotal(state, payload){
        state.total = payload
    }
}

const actions = {
    addProperty({ commit }, payload) {
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/properties', payload, headers())
          .then(res => {
            console.log({ res })
            return res.data
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      propertyTimeSlots({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/properties/time-slots/${payload}`, headers())
          .then(res => {
            return res.data
          })
          .catch(err => {
            console.log(err.response)
            return err.response
          })
      },
      getAllProperties({ commit }, { currentPage, perPage, sortItem, sortOrder, search }) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/all-properties?page=${currentPage}&per_page=${perPage}&sort=${sortItem}%7C${sortOrder}&search=${search}`, headers())
          .then(res => {
            console.log({ res })
            commit('setAllProperties', res.data.data)
            commit('setTotal', res.data.data.total)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getUserProperties({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/properties', headers())
          .then(res => {
            console.log({ res })
            commit('setUserProperties', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      updateProperty({ commit }, payload) {
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/properties/${payload.id}`, payload.data, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      deleteProperty({ commit }, payload) {
        return axios.delete(process.env.VUE_APP_BASE_URL + `/api/properties/${payload}`, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getProperty({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/properties/${payload.id}`, headers())
          .then(res => {
            console.log({ res })
            commit('setProperty', res.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getMyBuying({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/my-buying`, headers())
          .then(res => {
            console.log({ res })
            commit('setMyBuying', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getMyOffers({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/my-offers`, headers())
          .then(res => {
            console.log({ res })
            commit('setMyOffers', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getMyViewing({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/my-viewing`, headers())
          .then(res => {
            console.log({ res })
            commit('setMyViewing', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      createTimeSlot({ commit }, payload) {
        return axios.post(process.env.VUE_APP_BASE_URL + `/api/property-time-slot`, payload, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getPropertyMessages({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/property-messages/${payload}`, headers())
          .then(res => {
            console.log({ res })
            commit('setPropertyMessages', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      bookTimeSlot({ commit }, payload) {
        return axios.post(process.env.VUE_APP_BASE_URL + `/api/viewing`, payload, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      deleteTimeSlot({commit}, payload){
        return axios.delete(process.env.VUE_APP_BASE_URL + `/api/property-time-slot/${payload}`, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      updateTimeSlot({commit}, payload){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/property-time-slot/${payload.id}`,payload.data, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      updateViewing({commit}, { viewingId, messageId, data }){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/viewing/update/${viewingId}/${messageId}`, data, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      acceptBooking({ commit }, { timeSlotId, messageId }) {
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/viewing/accept/${timeSlotId}/${messageId}`, {}, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      rejectBooking({ commit }, { timeSlotId, messageId }) {
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/viewing/reject/${timeSlotId}/${messageId}}`, {}, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getFavourites({ commit }) {
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/favourites', headers())
          .then(res => {
            console.log({ res })
            commit('setFavourites', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      deleteFavourite({ commit }, payload) {
        return axios.delete(process.env.VUE_APP_BASE_URL + `/api/favourites/${payload}`, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      createFavourite({ commit }, payload) {
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/favourites', payload, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      postImage({ commit }, payload) {
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/base64', payload, headers())
          .then(res => {
            console.log({ res })
            return res.data
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getAllPackages({ commit }) {
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/package', headers())
          .then(res => {
            console.log({ res })
            commit('setPackages', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      packageSubscription({ commit }, payload) {
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/property-package', payload, headers())
          .then(res => {
            console.log({ res })
            return res.data
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      publish({ commit }, payload) {
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/property-published/${payload}`, {}, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      changePackageStatus({ commit }, payload) {
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/property-package/status/${payload}`, {}, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      createPropertyDraft({ commit, state }, payload) {
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/property-draft', payload, headers())
          .then(createPropertyDraft => {
            commit('setCurrentDraft', createPropertyDraft.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      getPropertyDraft({ commit }) {
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/property-draft', headers())
          .then(res => {
            console.log({ res })
            commit('setPropertyDraft', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      updatePropertyDraft({ commit }, payload) {
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/property-draft/${payload.id}`, payload.data, headers())
          .then(updatePropertyDraft => {
            console.log({ updatePropertyDraft })
            commit('setCurrentDraft', updatePropertyDraft.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      deletePropertyDraft({ commit }, payload) {
        return axios.delete(process.env.VUE_APP_BASE_URL + `/api/property-draft/${payload}`, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      createPropertyView({ commit }, payload) {
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/property-view', payload, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
      },
      buyerSellerChat({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/buyer-seller-chat/${payload.propertyId}/${payload.buyerId}`, headers())
          .then(res => {
            return res
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
        },
        buyerPropertyMessages({ commit }, payload) {
          return axios.get(process.env.VUE_APP_BASE_URL + `/api/buyer-property-messages/${payload}`, headers())
            .then(res => {
              return res
            })
            .catch(err => {
              console.log({ err })
              return err.response
            })
          },
      searchProperties({ commit }, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/search-properties?${payload}`, headers())
          .then(res => {
            console.log({ res })
            commit('setAllProperties', res.data.data)
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
        },
      addressLookup({ commit }, { postCode, houseNo }) {
        return axios.get(`https://api.getAddress.io/find/${postCode}?api-key=${process.env.VUE_APP_LOOKUP_KEY}&expand=true`).then(res => {
          console.log(res)
          commit('setSuggestions', res.data.addresses)
          return true
        })
        .catch(err => {
          console.log(err)
          commit('setSuggestions', [])
          return err.response
        })
      },
      getPropertyOffers({}, payload) {
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/property-offers/${payload}`, headers())
          .then(res => {
            console.log({ res })
            return res.data
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
        },
        changeOfferReadStatus({}, { id, data }){
          return axios.put(process.env.VUE_APP_BASE_URL + `/api/update-is-read/${id}`,data, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
        },
        removeFromMyBuying({}, payload){
          return axios.get(process.env.VUE_APP_BASE_URL + `/api/my-buying/remove/${payload}`, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
        },
        relist({}, payload){
          return axios.get(process.env.VUE_APP_BASE_URL + `/api/relist-property/${payload}`, headers())
          .then(res => {
            console.log({ res })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
        },
        solicitorAdd({}, payload){
          return axios.post(process.env.VUE_APP_BASE_URL + `/api/solicitor`, payload, headers())
          .then(solicitorAdd => {
            console.log({ solicitorAdd })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
        },
        solicitorUpdate({}, { id, data }){
          return axios.put(process.env.VUE_APP_BASE_URL + `/api/solicitor/${id}`, data, headers())
          .then(solicitorUpdate => {
            console.log({ solicitorUpdate })
            return true
          })
          .catch(err => {
            console.log({ err })
            return err.response
          })
        },
}

export default {
    state,
    getters,
    mutations,
    actions,
}