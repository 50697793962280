import axios from "axios";
import { headers } from "../../utils";

const state = {
    blogs: null,
    categories: null,
    tags: [],
    popularTags: null,
    total: 0,
}

const getters = {
    getBlogs(state){
        return state.blogs
    },
    getCategories(state){
        return state.categories
    },
    getTags(state){
        return state.tags
    },
    getPopularTags(state){
        return state.popularTags
    },
    getTotal(state){
        return state.total
    }
}

const mutations = {
    setBlogs(state, payload){
        state.blogs = payload
    },
    setCategories(state, payload){
        state.categories = payload
    },
    setTags(state, payload){
        state.tags = payload
    },
    setPopularTags(state, payload){
        state.popularTags = payload
    },
    setDefaultsBlog(state){
        state.blogs = null
        state.categories = null
        state.tags = []
        state.popularTags = null
    },
    setTotal(state, payload){
        state.total = payload
    }
}

const actions = {
    blogs({commit}, { page, perPage }){
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/blogs?page=${page}&per_page${perPage}`, headers())
        .then(res => {
            console.log({ res })
            commit('setBlogs', res.data.data.blogs)
            commit('setTotal', res.data.data.total)
            return true
          })
        .catch(err => {
            return err.response
        })
    },
    blog({commit}, payload){
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/blogs/show/${payload}`, headers())
        .then(res => {
            return res
        })
        .catch(err => {
            return err.response
        })
    },
    create({commit}, payload){
        return axios.post(`/api/blogs/create`,payload, headers())
        .then(() => {
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    delete({commit}, payload){
        return axios.delete(process.env.VUE_APP_BASE_URL + `/api/blogs/delete/${payload}`, headers())
        .then(() => {
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    update({commit}, payload){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/blogs/update/${payload.id}`,payload.data, headers())
        .then(() => {
            return true
        })
        .catch(err => {
            return err.response
        })
    },
    categories({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/blogs/post-category', headers())
        .then(res => {
            commit('setCategories', res.data.data)
            return true
        })
        .catch(err => {
            return err.response
        }) 
    },
    category({commit}, payload){
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/blogs/post-category/show/${payload}`, headers())
        .then(res => {
            return res
        })
        .catch(err => {
            return err.response
        }) 
    },
    tags({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/blogs/tags`, headers())
        .then(res => {
            commit('setTags', res.data.data)
            return true
        })
        .catch(err => {
            return err.response
        }) 
    },
    popularTags({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/blogs/tags/popular`, headers())
        .then(res => {
            commit('setPopularTags', res.data.data)
            return true
        })
        .catch(err => {
            return err.response
        }) 
    },
    createComment({commit}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + `/api/blogs/comment`,payload, headers())
        .then(res => {
            return true
        })
        .catch(err => {
            return err.response
        }) 
    },
    updateComment({commit}, payload){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/blogs/comment/${payload.id}`,payload.data, headers())
        .then(() => {
            return true
        })
        .catch(err => {
            return err.response
        }) 
    },
    deleteComment({commit}, payload){
        return axios.delete(process.env.VUE_APP_BASE_URL + `/api/blogs/comment/${payload}`, headers())
        .then(() => {
            return true
        })
        .catch(err => {
            return err.response
        }) 
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}