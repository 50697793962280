import axios from "axios";
import { headers } from "../../utils";

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
    createOffer({commit}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/offers', payload, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    acceptOffer({commit}, payload){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/accept-offer/${payload}`,{}, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    rejectOffer({commit}, payload){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/reject-offer/${payload}`,{}, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    updateOffer({}, { id, data }){
        return axios.put(process.env.VUE_APP_BASE_URL + `/api/offers/${id}`,data, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}