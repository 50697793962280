import Vue from "vue";
import VueRouter from "vue-router";
import DraftMiddleware from "../middlewares/drafts";
import EmailVerificationMiddleware from "../middlewares/email-verification";
import BlockedMiddleware from "../middlewares/blocked";
import Authentication from "../middlewares/authentication";
import { store } from "../store/index";

Vue.use(VueRouter);

const Index = () => import(/* webpackChunkName: "4FaDynq8Ol" */ "../pages/index.vue");
const Listing = () => import(/* webpackChunkName: "gWZaxuB49g" */ "../pages/listing.vue");
const Login = () => import(/* webpackChunkName: "FqLyIIYQOJ" */ "../pages/login.vue");
const Register = () => import(/* webpackChunkName: "PzXDX8fVCi" */ "../pages/register.vue");
const Blog = () => import(/* webpackChunkName: "VqXFbRfr7Q" */ "../pages/blog/index.vue");
const BlogsWithPagination = () => import(/* webpackChunkName: "VqXFbRdfsafr7Q" */ "../pages/blog/blogs-with-pagination.vue");
const BlogDetails = () => import(/* webpackChunkName: "uC61NCbxlx" */ "../pages/blog/details.vue");
const BrandAmbassador = () => import(/* webpackChunkName: "a8s1UOCW4t" */ "../pages/brand-ambassador.vue");
const ContactUs = () => import(/* webpackChunkName: "i74TJWqCoi" */ "../pages/contact-us.vue");
const AddProperty = () => import(/* webpackChunkName: "HKhT159wL2" */ "../pages/add-property.vue");
const Favourites = () => import(/* webpackChunkName: "Pizwjri0hu" */ "../pages/favourites.vue");
const MyBuying = () => import(/* webpackChunkName: "tWv5acKV7Z" */ "../pages/my-buying.vue");
const MyListing = () => import(/* webpackChunkName: "t3BrF2AhXo" */ "../pages/my-listing.vue");
const PropertyDetails = () => import(/* webpackChunkName: "FDPe0Ma65t" */ "../pages/property-details.vue");
const EditProperty = () => import(/* webpackChunkName: "6flNpgQdbF" */ "../pages/edit-property.vue");
const Profile = () => import(/* webpackChunkName: "Myo6G0SH1r" */ "../pages/profile.vue");
const ForgotPassword = () => import(/* webpackChunkName: "BHj7STHHUb" */ "../pages/forgot-password.vue");
const Blocked = () => import(/* webpackChunkName: "AqBiiId6c3" */ "../pages/blocked.vue");
const Congratulations = () => import(/* webpackChunkName: "rbpH2FOqJx" */ "../pages/congratulations.vue");
const EmailVerification = () => import(/* webpackChunkName: "0Nk3qltywV" */ "../pages/email-verification.vue");
const MobileScreen = () => import(/* webpackChunkName: "NkAn2ztUXm" */ "../pages/mobile-screen.vue");
const PrivacyPolicy = () => import(/* webpackChunkName: "JIuW4TZZyr" */ "../pages/privacy-policy.vue");
const Referral = () => import(/* webpackChunkName: "DNJKGUu0br" */ "../pages/referral.vue");
const ResetPassword = () => import(/* webpackChunkName: "0QkCMKA7MS" */ "../pages/reset-password.vue");
const Social = () => import(/* webpackChunkName: "fCn9i5y6hL" */ "../pages/social.vue");
const UsernameUnset = () => import(/* webpackChunkName: "HUXeQb8yW7" */ "../pages/username-unset.vue");
const HowItWorks = () => import(/* webpackChunkName: "HUXeQb22W7" */ "../pages/how-it-works.vue");
const GetStarted = () => import(/* webpackChunkName: "HmjeQb22W7" */ "../pages/get-started.vue");
const AcceptedOffer = () => import(/* webpackChunkName: "masd7uLp" */ "../pages/accepted-offer.vue");
const Welcome = () => import(/* webpackChunkName: "masd7uLprty" */ "../pages/welcome.vue");
const TermsAndConditions = () => import(/* webpackChunkName: "jscnhjbdwy" */ "../pages/terms-and-conditions.vue");
const VoucherOffersTerms = () => import(/* webpackChunkName: "astdbbbbd" */ "../pages/voucher-offers-terms.vue");
const DataDeletionPolicy = () => import(/* webpackChunkName: "ahfsdhfbhsdgf" */ "../pages/data-deletion-policy.vue");
const Error = () => import(/* webpackChunkName: "jsedcnhjbfgdwy" */ "../pages/error-404.vue");
const NewHome = () => import(/* webpackChunkName: "jsedcnhsssssjbfgdwy" */ "../pages/newhome.vue");

const routes = [
  {
    path: "/",
    name: "index-page",
    component: Index,
  },
  {
    path: "/listing",
    name: "listing",
    component: Listing,
    // beforeEnter: (to, from, next) => {
    //   if (!store.getters["getShowListingConfig"])
    //     store
    //       .dispatch("getConfigurationsByKey", "show_listing")
    //       .then((res) => {
    //         if (res.data.success) {
    //           if (eval(_.get(res, "data.data.val.value", false))) {
    //             return next()
    //           }
    //           next(from.path)
    //         }
    //         else {
    //           next(from.path)
    //         }
    //       });
    //   else {
    //     if (eval(_.get(store.getters["getShowListingConfig"], "val.value", false))) {
    //       return next()
    //     }
    //     next(from.path)
    //   }
    // }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/blogs-with-pagination",
    name: "blogs-with-pagination",
    component: BlogsWithPagination,
  },
  {
    path: "/blog/:slug",
    name: "blog-details",
    component: BlogDetails,
  },
  {
    path: "/become-an-affiliate",
    name: "brand-ambassador",
    component: BrandAmbassador,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/add-property",
    name: "add-property",
    component: AddProperty,
    beforeEnter: DraftMiddleware,
    meta: {
      loginRequired: true,
    }
  },
  {
    path: "/favourites",
    name: "favourites",
    component: Favourites,
    meta: {
      loginRequired: true,
    }
  },
  {
    path: "/my-buying",
    name: "my-buying",
    component: MyBuying,
    meta: {
      loginRequired: true,
    }
  },
  {
    path: "/my-listing",
    name: "my-listing",
    component: MyListing,
    meta: {
      loginRequired: true,
    }
  },
  {
    path: "/property-details/:id",
    name: "property-details",
    component: PropertyDetails,
  },
  {
    path: "/edit-property/:id",
    name: "edit-property",
    component: EditProperty,
    meta: {
      loginRequired: true,
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      loginRequired: true,
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/blocked",
    name: "blocked",
    component: Blocked,
    beforeEnter: BlockedMiddleware
  },
  {
    path: "/congratulations",
    name: "congratulations",
    component: Congratulations,
  },
  {
    path: "/email-verification",
    name: "email-verification",
    component: EmailVerification,
    beforeEnter: EmailVerificationMiddleware,
  },
  {
    path: "/mobile-screen",
    name: "mobile-screen",
    component: MobileScreen,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/voucher-and-offers-terms",
    name: "voucher-offers-terms",
    component: VoucherOffersTerms,
  },
  {
    path: "/data-deletion-policy",
    name: "data-deletion-policy",
    component: DataDeletionPolicy,
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: TermsAndConditions,
  },
  {
    path: "/referral",
    name: "referral",
    component: Referral,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
  },
  {
    path: "/social",
    name: "social",
    component: Social,
  },
  {
    path: "/username-unset",
    name: "username-unset",
    component: UsernameUnset,
  },
  {
    path: "/how-it-works",
    name: "how-it-works",
    component: HowItWorks,
  },
  {
    path: "/get-started",
    name: "get-started",
    component: GetStarted,
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
  },
  {
    path: "/newhome",
    name: "newhome",
    component: NewHome,
  },
  {
    path: "/error-404",
    name: "error",
    component: Error,
  },
  {
    path: "/accepted-offer/:propertyId/:toUserId",
    name: "accepted-offer",
    component: AcceptedOffer,
    meta: {
      loginRequired: true,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(Authentication);

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router