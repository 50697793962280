import axios from "axios";
import { headers } from "../../utils";

const state = {
    configurations: [],
    showListingConfig: null,
}

const getters = {
    getConfigurations(state){
        return state.configurations
    },
    getShowListingConfig(state){
        return state.showListingConfig
    }
}

const mutations = {
    setConfigurations(state, payload){
        state.configurations = payload
    },
    setDefaultsConfigurations(state){
        state.configurations = []
    },
    setShowListingConfig(state, payload){
        state.showListingConfig = payload
    }
}

const actions = {
    getConfigurations({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/common/configurations', headers())
        .then(res => {
            console.log({res})
            commit('setConfigurations', res.data.data)
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    createConfiguration({commit}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/common/configurations',payload, headers())
        .then(res => {
            console.log({res})
            return true
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
    getConfigurationsByKey({commit}, payload){
        return axios.get(process.env.VUE_APP_BASE_URL + `/api/common/configurations/get-by-key/${payload}`, headers())
        .then(res => {
            if(payload === "show_listing")
                commit("setShowListingConfig", res.data.data)
            return res
        })
        .catch(err => {
            console.log({err})
            return err.response
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}