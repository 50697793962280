import Vue from 'vue'
import App from './App.vue'
import { store } from './store/index'
import router from './router/index'
import lazyLoading from "./directives/lazyLoading"
import Vuelidate from 'vuelidate'
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueSocialSharing from 'vue-social-sharing'

import * as VueGoogleMaps from 'vue2-google-maps'

import VueFacebookPixel from 'vue-analytics-facebook-pixel'
 
Vue.use(VueFacebookPixel)

/**
 * Init facebook tracking pixel
 * @param  {String} appId 
 * @param  {object} [data={}] 
 */

Vue.analytics.fbq.init('213754684142521')

Vue.analytics.fbq.event('PageView')

// fbq('init', '213754684142521'); 
// fbq('track', 'PageView');
 
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  },
})

Vue.use(VueSocialSharing);


Vue.use(VueFormWizard)
Vue.use(Vuelidate)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import {$,jQuery} from "jquery";
window.$ = $
window.jQuery = jQuery
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/js/jquery-ui.min.js'
import './assets/js/jquery-ui.min.css'
import './assets/css/google-fonts.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.directive('lazyload',
    lazyLoading
)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
