<template>
  <div id="app">
    <div id="mainDiv">
      <Header @headerLoaded="headerLoaded = $event"></Header>
      <router-view :key="$route.path"></router-view>
      <Footer></Footer>
      <div :class="showLoader ? '' : 'd-none'">
        <div id="preloader">
          <div id="status">
            <div class="status-mes"></div>
          </div>
        </div>
      </div>
      <!-- END PRELOADER -->
      <b-modal
        @hide="hideModal"
        :hide-header="true"
        :hide-footer="true"
        centered
        ref="loginRequiredModal"
        title="Login Required"
      >
        <div id="login">
          <div class="login">
            <form @submit.prevent="">
              <div class="access_social">
                <!-- <a @click="facebookLogin()" href="#0" class="social_bt facebook d-none"
                  >Continue with Facebook</a> -->

                <a @click="googleLogin()" href="#0" class="social_bt google"
                  >Continue with Google</a
                >
              </div>
              <div class="divider"><span>Or</span></div>
              <div class="form-group">
                <label>Email</label>
                <input
                  :class="
                    $v.email.$dirty && !$v.email.required ? 'error-border' : ''
                  "
                  v-model="email"
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                />
                <span class="error" v-if="$v.email.$dirty && !$v.email.required"
                  >This field is required!</span
                >
                <i class="icon_mail_alt"></i>
              </div>
              <div class="form-group">
                <label>Password</label>
                <input
                  :class="
                    ($v.password.$dirty && !$v.password.required) ||
                    ($v.password.$dirty && !$v.password.minLength)
                      ? 'error-border'
                      : ''
                  "
                  v-model="password"
                  type="password"
                  class="form-control"
                  name="password"
                  id="password"
                  value=""
                />
                <span
                  class="error"
                  v-if="$v.password.$dirty && !$v.password.required"
                  >This field is required!</span
                >
                <span
                  class="error"
                  v-if="$v.password.$dirty && !$v.password.minLength"
                  >Password must be 8 digits long</span
                >
                <i class="icon_lock_alt"></i>
              </div>
              <div class="fl-wrap filter-tags clearfix add_bottom_30">
                <div class="checkboxes float-left">
                  <div class="filter-tags-wrap">
                    <input id="check-b" type="checkbox" name="check" />
                    <label for="check-b">Remember me</label>
                  </div>
                </div>
                <div class="float-right mt-1">
                  <a id="forgot" href="javascript:void(0);">Forgot Password?</a>
                </div>
              </div>
              <button
                style="outline: none"
                @click="login()"
                class="btn_1 rounded full-width"
              >
                Login to Vencasa
              </button>
              <div class="text-center add_top_10">
                New to Find Houses?
                <strong
                  ><router-link to="/register">Sign up!</router-link></strong
                >
              </div>
            </form>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import loginMixin from "./mixins/loginMixin";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      headerLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      message: "getMessage",
      shouldShow: "getShouldShow",
      showLoader: "getShowLoader",
      loginRequired: "getLoginRequired",
    }),
  },
  mixins: [loginMixin],
  components: {
    Header: () =>
      import(/* webpackChunkName: "header" */ "./components/header.vue"),
    Footer: () =>
      import(/* webpackChunkName: "footer" */ "./components/footer.vue"),
  },
  watch: {
    headerLoaded() {
      var userDropdown = document.querySelector(".user-menu");
      var mainDivOfLayout = document.querySelector("#mainDiv");
      mainDivOfLayout.addEventListener("click", () => {
        if (userDropdown.classList.value.includes("collapse")) {
          userDropdown.classList.remove("collapse");
          userDropdown.classList.toggle("active");
        } else if (userDropdown.classList.value.includes("active")) {
          userDropdown.classList.remove("active");
        }
      });
      document.querySelector("body").addEventListener("click", (e) => {
        if (
          !e.target.classList.contains("navbar-toggler") &&
          !e.target.classList.contains("navbar-toggler-icon") &&
          document
            .querySelector(".navbar-toggler")
            .classList.contains("not-collapsed")
        ) {
          document.querySelector(".navbar-toggler").click();
        }
      });
    },
    loginRequired(val) {
      val
        ? this.$refs.loginRequiredModal.show()
        : this.$refs.loginRequiredModal.hide();
    },
    showLoader(val) {
      console.log({ val }, "val");
    },
    shouldShow(val) {
      if (val) this.makeToast(this.message);
    },
  },
  created() {
    document.title = "Vencasa";
  },
  methods: {
    async login() {
      if (this.$v.$invalid) {
        if (this.$v.email.$invalid) this.$v.email.$touch();
        if (this.$v.password.$invalid) this.$v.password.$touch();
      } else {
        this.$store.commit("setShowLoader", true);
        const data = {
          grant_type: "password",
          client_id: process.env.VUE_APP_client_id,
          username: this.email,
          password: this.password,
          client_secret: process.env.VUE_APP_client_secret,
        };
        await this.$store.dispatch("login", data).then((res) => {
          this.$store.commit("setShowLoader", false);
          if (res == true) {
            this.$refs.loginRequiredModal.hide();
            if (Object.keys(this.$route.query).includes("sell"))
              this.$router.push("/add-property");
            else if (Object.keys(this.$route.query).includes("get-started"))
              this.$router.push("/welcome");
            else this.$router.push("/");
          } else if (res.status == 400 && res.data.error === "invalid_grant") {
            this.invalidLoginDetails = true;
          }
        });
      }
    },
    toRegister() {
      this.$refs.loginRequiredModal.hide();
      this.$router.push("/register");
    },
    hideModal() {
      this.$store.commit("setLoginRequired", false);
    },
    makeToast(message) {
      this.$bvToast.toast(`${message}`, {
        title: "Success!",
        autoHideDelay: 5000,
        solid: true,
      });
      this.$store.commit("setShouldShow", false);
    },
  },
};
</script>

<style scoped>
#preloader {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

#status,
.status-mes {
  background-image: url("./assets/images/preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
  height: 200px;
  left: 50%;
  margin: -100px 0 0 -100px;
  position: absolute;
  top: 50%;
  width: 200px;
}

div >>> .toast-body {
  padding-bottom: 25px !important;
  color: white;
  text-align: center;
  padding-top: 25px !important;
  font-size: 19px;
  background-color: green;
}

div >>> .toast-header {
  display: none;
}

.status-mes {
  background: none;
  left: 0;
  margin: 0;
  text-align: center;
  top: 65%;
}

html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}

@font-face {
  font-family: futura;
  src: url("./assets/fonts/Futura Bold.otf");
}
/* @font-face {
  font-family: futura-light;
  src: url("./assets/fonts/futura light/Futura Std Light Condensed.ttf");
} */
</style>

<style scoped src="./assets/css/styles.css"></style>
