import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import configurations from './modules/configurations'
import blog from './modules/blog'
import auth from './modules/auth'
import property from './modules/property'
import report from './modules/report'
import miscellaneous from './modules/miscellaneous'
import coupon from './modules/coupon'
import referral from './modules/referral'
import message from './modules/message'
import Payment from './modules/payment'
import Offers from './modules/offers'

export const store = new Vuex.Store({
    modules:{
        configurations,
        blog,
        auth,
        property,
        report,
        miscellaneous,
        coupon,
        referral,
        message,
        Payment,
        Offers,
    }
})