import axios from "axios";
import { headers } from "../../utils";

const state = {
    coupons: [],
    couponByVal: null,
}

const getters = {
    getCoupons(state){
        return state.coupons
    },
    getCouponByVal(state){
        return state.couponByVal
    }
}

const mutations = {
    setCoupons(state, payload){
        state.coupons = payload
    },
    setCouponByVal(state, payload){
        state.couponByVal = payload
    },
    setDefaultsCoupon(state){
        state.coupons = []
        state.couponByVal = null
    }
}

const actions = {
    getAllCoupons({commit}){
        return axios.get(process.env.VUE_APP_BASE_URL + '/api/coupon/coupons')
        .then(res => {
            commit('setCoupons', res.data.data)
        })
        .catch(err => {
            console.log(err)
        })
    },
    getByCouponCode({commit}, payload){
        return axios.post(process.env.VUE_APP_BASE_URL + '/api/coupons/get-by-code', payload, headers())
        .then(res => {
            commit('setCouponByVal', res.data.data ? res.data.data : null)
            return res
        })
        .catch(err => {
            console.log(err)
            return err.response
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}